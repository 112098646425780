import { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { SendCodeSpinner } from 'SharedComponents/send_code_from_sms';
import { withFetching } from 'bv-hocs';
import { moveToEnableTwoFA } from 'TwoFA/helpers/common';
import ManualBlock from './manual_block';
import getQrCode from '../helpers/api';
import withCheckVisibility from '../../../hocs/with_check_visibility';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manual: false,
    };
  }

  showManual = () => {
    this.setState({
      manual: true,
    });
  };

  render() {
    const { manual } = this.state;
    const { secret, qr, email } = this.props;

    return (
      <div className="bvs-card twofa-totp-scan twofa__container">
        <div className="twofa-totp-intro-header">
          <div className="twofa-totp-intro__icon" />
          <p className="twofa-totp-intro__intro-text">
            {t('javascript.twofa.settings.totp.scan.header')}
          </p>
        </div>
        <hr />
        { secret === ''
          ? (
            <SendCodeSpinner />
          ) : (
            <div className="twofa-totp-scan-body">
              <img className="twofa-totp-scan-body__qr" alt="QR code" src={qr} />
              <div className={`twofa-totp-scan-body-buttons ${manual ? 'twofa-totp-scan-body-buttons--manual_code' : ''}`}>
                <Button
                  className="twofa-totp-scan-body-buttons__next-button"
                  primary
                  onClick={moveToEnableTwoFA}
                  label={t('javascript.twofa.settings.totp.scan.next_button')}
                />
                <ManualBlock
                  open={manual}
                  showManual={this.showManual}
                  email={email}
                  code={secret}
                />
              </div>
            </div>
          )}
      </div>
    );
  }
}

App.propTypes = {
  secret: PropTypes.string.isRequired,
  qr: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default withCheckVisibility('totp')(withFetching(getQrCode, null, { spread: true })(App));
