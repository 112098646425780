import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const TotpButton = (props) => (
  <Button
    className="twofa-totp-scan__show-button"
    secondary
    {...props}
  />
);

class ManualBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };

    this.codeText = createRef();
  }

  copyCode = () => {
    this.codeText.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    this.setState({
      copied: true,
    });

    if (!this.copyFeedbackTimeout) {
      this.copyFeedbackTimeout = setTimeout(() => {
        this.setState({
          copied: false,
        });
        delete this.copyFeedbackTimeout;
      }, 3000);
    }
  };

  isIosDevice = () => (window.navigator && window.navigator.platform && /iP(ad|hone|od)/.test(window.navigator.platform));

  render() {
    const { copied } = this.state;
    const {
      open, showManual, email, code,
    } = this.props;

    if (!open) {
      return (
        <TotpButton
          inverse
          onClick={showManual}
          label={t('javascript.twofa.settings.totp.scan.manual_button')}
        />
      );
    }

    return (
      <div className="twofa-totp-scan-code-opened">

        <span className="twofa-totp-scan-code-opened__account-label">{t('javascript.twofa.settings.totp.scan.account_label')}</span>
        {' '}
        <span className="twofa-email">{email}</span>

        <div className="twofa-totp-scan-code-opened-code">
          <textarea ref={this.codeText} readOnly className="twofa-totp-scan-code-opened-code__text">
            {code}
          </textarea>
          {copied && (
            <div className="twofa-totp-scan-code-opened-code__overlay">
              <p>{t('javascript.twofa.settings.totp.scan.copy_feedback')}</p>
            </div>
          )}
        </div>
        {!this.isIosDevice() && (
        <TotpButton
          onClick={this.copyCode}
          label={t('javascript.twofa.settings.totp.scan.copy_button')}
        />
        )}
      </div>
    );
  }
}

ManualBlock.propTypes = {
  open: PropTypes.bool.isRequired,
  showManual: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default ManualBlock;
