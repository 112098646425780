import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStatus from './with_status';

export default (method = '') => (WrappedComponent) => withStatus((() => {
  class Filter extends PureComponent {
    render() {
      let render = false;
      const { enabledTotp, enabledSms } = this.props;

      switch (method) {
        case 'totp':
          render = enabledTotp != null;
          break;
        case 'sms':
          render = enabledSms != null;
          break;
        default:
          render = true;
      }
      if (!render) {
        return null;
      }

      return (
        <WrappedComponent {...this.props} />
      );
    }
  }

  Filter.propTypes = {
    enabledTotp: PropTypes.bool.isRequired,
    enabledSms: PropTypes.bool.isRequired,
  };

  return Filter;
})());
